import React from 'react';
import audio1 from "../assets/audio/audio (1).mp3"
import audio2 from "../assets/audio/audio (2).mp3"
import { FcAudioFile } from "react-icons/fc"
import { BiSolidDownload } from "react-icons/bi"
class AudioPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.audioRef = React.createRef();
        this.state = {
            audioFiles: [
                {
                    src: audio1,
                    name: 'Meeting audio.mp3',
                    date: "21-Feb-2023",
                    size: '10KB',
                },
                {
                    src: audio2,
                    name: 'Seamus.mp3',
                    date: "21-Sep-2023",
                    size: '1MB',
                },
            ],
        };
    }

    componentDidMount() {
        this.fetchFileSizes();
    }
    fetchFileSizes = () => {
        const { audioFiles } = this.state;

        audioFiles.forEach((audioFile, index) => {
            fetch(audioFile.src)
                .then((response) => response.headers.get('content-length'))
                .then((fileSize) => {
                    const updatedAudioFiles = [...audioFiles];
                    updatedAudioFiles[index].size = fileSize;
                    this.setState({ audioFiles: updatedAudioFiles });
                })
                .catch((error) => {
                    console.error('Error retrieving file size:', error);
                });
        });
    };

    handlePlayClick = (src) => {
        this.audioRef.current.src = src;
        this.audioRef.current.play();
    };

    handleDownloadClick = (src, name) => {
        const link = document.createElement('a');
        link.href = src;
        link.download = name;
        link.click();
    };

    render() {
        const { audioFiles } = this.state;
        return (
            <div className='container'>
                <div className='audioHeight'>
                    <audio ref={this.audioRef}>
                        <source src="" type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                    <table className='table audioTable table-hover'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Last Modified</th>
                                <th>Size</th>
                                <th>Description</th>
                            </tr>
                        </thead>

                        {audioFiles.map((audio, index) => (
                            <tr key={index}>
                                <td>
                                    {/* <button onClick={() => this.handlePlayClick(audio.src)} ><FcAudioFile /></button> */}
                                    <div className='d-flex justify-content-between w-100'>
                                        <p className='mb-0'> {audio.name}</p>
                                        <button onClick={() => this.handleDownloadClick(audio.src, audio.name)} className='musicIcon'>
                                            <BiSolidDownload />
                                        </button>

                                    </div>
                                </td>
                                <td>{audio.date}</td>
                                <td>{audio.size ? `${audio.size} bytes` : 'Calculating...'}</td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
        );
    }
}

export default AudioPlayer;
