import React from 'react'
import "../HomeStyle.css"
import "../responsive.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Keyboard } from "swiper";

const MemberSlider = ({ data }) => {
    if (!data) {
        return null; // Or handle the case where data is not available
    }
    const { name, photo, designation } = data;

    return (
        <div>
            <Swiper
                loop={true}
                keyboard={true}
                className="memberSwiper"
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                spaceBetween={40}
                slidesPerView={1}
                modules={[Autoplay, Keyboard]}
                breakpoints={{
                    550: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    820: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    991: {
                        slidesPerView: 3,
                        spaceBetween: 60,
                    },
                    1300: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    }


                }}
            >
                <SwiperSlide>
                    <div className='memberContainer'>
                        {/* <p className='committee'>{content.committee}</p> */}
                        <img src={photo} alt="members" />
                        <div className='absoluteMember'>
                            <p className='memberSlideHeading'>{name}</p>
                            <p className='memberSlideTitle'>{designation}</p>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div >
    )
}

export default MemberSlider