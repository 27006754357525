
import React, { useState } from "react";
import { Link } from 'react-router-dom'
import Logo from "../assets/images/Nephro Logo 1.png"
import Close from "../assets/images/closeIcon.png"
const Header = () => {
    const [toggle, setToggle] = useState(false)
    const handleToggle = () => {
        return (setToggle(!toggle))
    }
    return (
        <div>
            <nav className={`navbar navbar-expand-lg  homeNavbar `}>
                <div className="container-fluid">
                    <Link to={"/"} className="navbar-brand" ><img src={Logo} alt="logo" /> </Link>
                    <button className="navbar-toggler" type="button" onClick={handleToggle} ><span className="navbar-toggler-icon"></span></button>
                    <div className={`mobileFixed ${toggle && "Visiable"}`}>
                        <button type="button" className="closeBtn" onClick={handleToggle}> <img src={Close} alt="toggleBar" /> </button>
                        <ul className="navbar-nav ">
                            <li className="nav-item" onClick={handleToggle}><Link to={"/"} className="nav-link">Home</Link></li>
                            <li className="nav-item" onClick={handleToggle}><a className="nav-link" href="#members">Members</a></li>
                            <li className="nav-item" onClick={handleToggle}><a className="nav-link" href="#conference">Conference</a></li>
                            <li className="nav-item" onClick={handleToggle}><Link className="nav-link" to={"/conference"}>ISNSCCON2023</Link></li>
                            <li className="nav-item" onClick={handleToggle}><a className="nav-link" href="#gallerys">Gallery</a></li>
                            {/* <li className="nav-item" onClick={handleToggle}><a className="nav-link" href="#about">About Us</a></li> */}
                            <li className="nav-item" onClick={handleToggle}><a className="nav-link" href="#contact">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}
export default Header