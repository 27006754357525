import React from 'react'
import DayConference from './DayConference';

const EventDetailsMap = (props) => {
    const { datas } = props;
    return (
        <div>
            {datas?.map((data, index) => {
                return (
                    <section className='commonPadding removeBottomPadding' key={index}>
                        <div className='container'>
                            <DayConference
                                title="Pre Conference WorkShop" 
                                day={data?.title}
                                venue={data?.venue}
                                topic={data?.topic}
                                topics={data.speakerVideo}
                                speakers={data?.speakers}
                                photos={data.photos}
                            />
                        </div>
                    </section>
                )
            })}


        </div>
    )
}

export default EventDetailsMap