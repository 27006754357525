import React from 'react'
import { Link } from 'react-router-dom'
import Logo from "../assets/images/Nephro Logo 1.png"
import "../HomeStyle.css"
import "../responsive.css"
const Footer = () => {
    return (
        <div className='footer'>
            <div className='row'>
                <div className='col-md-6 col-12 col-lg-3 col-sm-12 col-xl-3'>
                    <img src={Logo} alt="logo" className='footerLogo' />
                </div>
                <div className='col-md-6 col-12 col-lg-6 col-sm-12 col-xl-6'>
                    <p className='footerTitle'>Contact</p>
                    <div className='address'>
                        <p className='addressHeading'>Administrative Office</p>
                        <address>
                            Nephrology Association of Coimbatore,<br />
                            D701, TULIP Apartments, Phase2, <br />
                            Udayamplayam Road, Nava India, <br />
                            Sowripalayam, Coimbatore.<br />
                        </address>
                    </div>
                    <p className='phoneNumber'>Phone Number: +91 8508286164</p>
                    <p className='phoneNumber'>Email: nephroassociationcbe@gmail.com</p>
                </div>
                <div className='col-md-6 col-12 col-lg-3 col-sm-12 col-xl-3'>
                    <p className='footerTitle'>Menu</p>
                    <ul>
                        <li><Link to={"/"}
                        >Home</Link></li>
                        <li><a href='#members'>Members</a></li>
                        <li><a href='#gallerys'>Gallery</a></li>
                        <li><a href='#conference'>Conference</a></li>
                        <li><a href='#contact'>Contact Us</a></li>
                        {/* <li><a href='#about'>About Us</a></li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default Footer