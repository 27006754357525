import React, { useEffect, useState } from 'react'
import Logo1 from "../assets/images/logo (2).png"
import Logo2 from "../assets/images/logo.png"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import BannerImg from "../assets/images/banner.png"
import MemberSlider from '../Components/MemberSlider'
// import { Link } from 'react-router-dom'
import "../responsive.css"
import axios from 'axios'
import EventDetailsMap from '../Components/EventDetailsMap'
import { domain } from '../Constants'
const Conference = () => {
    const [data, setData] = useState([]);
    // const id = ""
    useEffect(() => {
        axios.get(domain + `/api/event`)
            .then(response => {
                setData(response.data.data[0]);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);
    // const video = [
    //     {
    //         topic: "yes",
    //         videoUrl: "https://www.youtube.com/embed/9QoKRGNHBcY?rel=0"
    //     },
    //     {
    //         topic: "yes",
    //         videoUrl: "https://www.youtube.com/embed/9QoKRGNHBcY?rel=0"
    //     },
    //     {
    //         topic: "yes",
    //         videoUrl: "https://www.youtube.com/embed/9QoKRGNHBcY?rel=0"
    //     }
    // ]

    return (
        <div>
            <section>
                <div className='bannerConference' style={{
                    backgroundImage: `url(${BannerImg})`
                }}>
                    <div className='d-flex flex-wrap-reverse bannerBox'>
                        <div className='col-lg-4  col-12'>
                            <div className="audioRoute">
                                <a href='#agenta' className="audioText" rel="noreferrer" >Summary</a>
                                <a href='https://app.box.com/s/avks204t55v7cx3dy97x7lv0ifxc1ond' rel="noreferrer" className="audioText" target="_blank">Souvenir</a>
                                <a href='#gallerys' rel="noreferrer" className="audioText" >Gallery</a>
                                <a href='#videos' rel="noreferrer" className='audioText'>Videos</a>
                                <a href="https://app.box.com/s/yd1k2nm4vx46wtfgnuiufubfz6hx9dng" rel="noreferrer" className="audioText" target="_blank">Presentations</a>
                                {/* <Link to={"https://drive.google.com/drive/u/2/folders/1MUrUesNoqbmQB_NdkFNpUCIEloToUqBP"} className="audioText" >Presentations</Link> conference/newlist */}
                            </div>
                        </div>
                        <div className='col-lg-8  col-12'>
                            <div className='bannerDetails'>
                                <div className='LogoImages'>
                                    <img src={Logo1} alt='logo' />
                                    <img src={Logo2} alt='logo' />
                                </div>
                                <div className='bannerText'>
                                    <p className='bannerSmallText'>42nd Annual Conference of</p>
                                    <h1 className='bannerBigText'>Indian Society of   </h1>
                                    <br></br>
                                    <h2 className='bannerBigText'>Nephrology   </h2>
                                    <h3 className='bannerCap'>(SOUTHERN CHAPTER-ISNSCCON 2023)</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bannerRightText'>
                        <p><span>Held on: </span> {data ? (data?.eventDates?.[0]?.eventDate) : (<Skeleton />)}</p>
                        <p><span>Venue: </span>{data?.eventDates?.[0]?.venue}</p>
                    </div>
                </div>
            </section >
            <section className='meeting commonPadding' id="agenta">
                <div className='container'>
                    <h1 className='meetingHeading firstPadding'>Meeting Summary</h1>
                    {data.agedaOfMeeting && (
                        <p className='meetingSubText pl'>{data.agedaOfMeeting}</p>
                    )}
                </div>
            </section>
            {data.eventCommiteeMember && data.eventCommiteeMember.length > 0 && (<section className='commonPadding' id='meeting'>
                <div className='container'>
                    <h1 className='meetingHeading'>Conference Committee Members </h1>
                </div>
                <div className='memberSlider pl'>
                    <MemberSlider data={data.eventCommiteeMember} />
                </div>
            </section>)}

            <EventDetailsMap datas={data?.eventDates} />
            {/* <VideoSlider data={video} /> */}

        </div >
    )
}

export default Conference