import React, { useState } from 'react';
const Gallery = (props) => {
    const { text, images } = props
    const [slider, setSlider] = useState(0)
    const [open, setOpen] = useState(false)
    const handleImageClick = (index) => {
        setSlider(index)
        setOpen(true)
    }

    return (
        <div>{text === "index" ? <h1 className='galleryImg'>Gallery</h1> : ""}
            <div id="gallery">
                {images?.map((image, index) => (
                    <img
                        key={index}
                        src={text === "index" ? image.imageUrl : image}
                        alt="Gallery"
                        className='img-responsive'
                        onClick={() => handleImageClick(index)}
                    />
                ))}
            </div>
            {open &&
                <div className='popupImageContainer' onClick={() => setOpen(!open)}>
                    <h1 className='galleryImg'>Gallery</h1>
                    <div className='popupRelative'>
                        <img src={text === "index" ? images[slider].imageUrl : images[slider]} className="currentImage" alt="galleryImages" />
                    </div>
                </div>}
        </div>
    )
}
export default Gallery