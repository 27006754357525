import React from 'react'

const Doctors = ({ details, heading, padding, widthOne, widthTwo }) => {
    return (
        <section className={`sectionThree container ${padding}`}>
            <p className='headingSmall'>{heading}</p>
            <div className='d-flex flex-wrap gap-4 align-items-center justify-content-center'>
                {details && (details.map(item => (
                    <div className={`cardWidth ${widthOne}`} key={item.id}>
                        <div className={`cardChild ${widthTwo}`}>
                            <img src={item.photo} alt="doctorsImages" className='cardImg' />
                        </div>
                        <p className='cardName'>{item.name}</p>
                    </div>
                )))}
            </div>
        </section>
    )
}

export default Doctors