import 'bootstrap/dist/css/bootstrap.css';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './Layout';
import Conference from './Pages/Conference';
import IndexPage from './Pages/IndexPage';
import List from "./Components/List"
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<IndexPage />} />
          <Route path="/conference" element={<Conference />} />
          <Route path="/conference/newlist" element={<List />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
