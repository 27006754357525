import React from 'react'
import "../HomeStyle.css"
import "../responsive.css"
import Gallery from './Gallery'
import VideoSlider from './VideoSlider'
const DayConference = (props) => {
    const { venue, day, topic, topics, speakers, photos } = props
    return (
        <div>
            {/* <h1 className='dayHeading'>{topic} </h1> */}
            <p className='dayCount'>{day}</p>
            <div className='venueSection'>
                <p className='colorSmallText'><span className='colorSpan'>Venue: </span>{venue}</p>
            </div>
            <dl>
                <dt className='colorSmallText'><span className='colorSpan'>Topic: </span>
                    {topic}
                </dt>
                {/* <dd>
                    <ul className='topicList'>
                        {topics?.map((data,index) => {
                            return (
                                <li className='colorSmallText' key={index}>{data.topic}</li>
                            )
                        })}
                    </ul>
                </dd> */}

                {speakers && speakers.length > 0 &&
                    (<>
                        <dt className='colorSmallText'><span className='colorSpan' >Speakers: </span></dt>
                        <dd>
                            <div className='profileGroup'>
                                {speakers?.map((speaker, index) => {
                                    return (
                                        <div key={index}>
                                            <img src={speaker.photo} className="Cprofile" alt='profile' />
                                            <p className='CprofileName'>{speaker.name}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </dd>
                    </>
                    )}
                {photos && photos.length > 0 &&
                    (<>
                        <section className='commonPadding CPadding ' id="gallerys">
                            <div className='container'>

                                <div className='galleyCon'>
                                    <Gallery text="conference" images={photos} />
                                </div>
                            </div>
                        </section>
                    </>
                    )}
                {topics && topics.length > 0 &&
                    (<> <section className='commonPadding ' id="videos">
                        <div className='container'>
                            <span className='colorSpan videoTitle'>Videos:</span>
                            {/* <p className='videoPadding'>The organizing committee of Indian Society of Nephrology Southern Chapter welcomes you to the 42nd annual conference of ISNSC ( ISNSCCON 2023 ) which is being organized under the aegis of Nephrology Association of Coimbatore, Tamil Nadu.</p> */}
                        </div>
                        <VideoSlider data={topics} />
                    </section></>)}
            </dl>
        </div>
    )
}
export default DayConference