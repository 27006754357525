import React from 'react'
import "../HomeStyle.css"
import "../responsive.css"
import { BsBoxArrowUpRight } from "react-icons/bs"

const VideoSlider = (props) => {
    const { data } = props;
    return (
        <div className='pb-5'>
            <div className='d-flex align-items-center flex-wrap'>
                {
                    data?.map((content, index) => {
                        const caricatureImage = (content.Caricature == null || content.Caricature == "") ? "https://www.w3schools.com/howto/img_avatar.png" : content.Caricature;
                        return (
                            <div className='col-lg-4 col-12 col-md-6' key={index}>
                                <div className='videoSize mb-3'>
                                    <div className='d-flex align-items-center'>
                                        <img src={caricatureImage} alt='Caricature' className='YoutubeTopicImage' />
                                        <p className='mb-1'>{`${content.topic}`}</p>
                                    </div>
                                    <a href={`${content.ppt}`} rel="noreferrer" alt="ppt" className='d-block mb-2' target='_blank'>PPT <BsBoxArrowUpRight /></a>
                                    <div className='YoutubeFrameSize'>
                                        <iframe src={`https://www.youtube.com/embed/${content.videoUrl.substr(17, content.videoUrl.length)}`} title="YouTube video player" frameBorder="0"
                                            allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }
            </div>


        </div >
    )
}

export default VideoSlider