import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Pagination, Keyboard } from "swiper";

// import "swiper/css";
// import "swiper/css/pagination";
import "../HomeStyle.css"
import "../responsive.css"

import Gallery from '../Components/Gallery';
import Doctors from '../Components/Doctors';
// import TabSlider from '../Components/TabSlider';

import { domain } from '../Constants';
import Banner1 from "../assets/images/HomeBanner.svg"
import BannerLogo from "../assets/images/HomeBannerLogo.png"
import instagram from "../assets/images/Vector.png"
import facebook from "../assets/images/Vector (1).png"
import docplaceholder from "../assets/images/DocPlaceHolder.svg"
// import JsonData from '../assets/nephro_db.committees.json'
// import patrons from "../assets/patrons"
// import advisors from "../assets/advisors"
// import Palace from "../assets/images/palace_logo_2.png"
// import Banner1 from "../assets/images/Rectangle 2.png"

const IndexPage = () => {
    // const commiteeApiEndPointLive = "http://13.127.48.129:8080/api/committee/members"
    const [data, setData] = useState({
        "committees": [],
        "others": [],
        "patrons": [],
        "advisors": []
    });
    const [getImage, setGetImage] = useState([]);
    // const officeBearers = JsonData.data.office_bearers;
    // const [prevevent, setprevEventDate] = useState([])
    // const [upevent, setupEventDate] = useState([]);


    useEffect(() => {
        axios.get(domain + '/api/gallery')
            .then(response => {
                setGetImage(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    // console.log(data)
    // let SliderArray = [
    //     {
    //         title: "The kidney is the body's natural filter, removing waste and toxins from the blood to keep us healthy.",
    //         sliderImage: Banner1

    //     },
    //     {
    //         title: "The kidney is the body's natural filter, removing waste and toxins from the blood to keep us healthy.",
    //         Anchor: "Learn More",
    //         sliderImage: Banner1

    //     }
    // ]
    // const [activeTab, setActiveTab] = useState(0);
    // const handleTabChange = (tabIndex) => {
    //     setActiveTab(tabIndex);
    // };

    // const otherDoctors = [
    //     "Dr V Mangala Kumar",
    //     "Dr S Ramaswami",
    //     "Dr R Gandhi Mohan",
    //     "Dr A Prabhakar",
    //     "Dr P Giridesh",
    //     "Dr K Yuvaraja",
    //     "Dr J Gerard Vinodh",
    //     "Dr Aishwarya D",
    //     "Dr Iswarya Annamalai",
    //     "Dr P Shrijja",
    //     "Dr K Prem Anand",
    //     "Dr C Shakthi Kumar",
    //     "Dr S P S Anandan",
    //     "Dr Madhav Venkatesan",
    //     "Dr S Sabarinath",
    //     "Dr G Madhushankar",
    //     "Dr G Saravanan",
    //     "Dr G Vikraman",
    //     "Dr G Vasanth",
    //     "Dr S Kiruthika",
    //     "Dr V Nagendran",
    //     "Dr V. Krishnakumar",
    //     "Dr. T. Chakravarthy",
    //     "Dr P N Vijay Aanand Siddharth",
    //     "Dr. S. Arivazhagan",
    //     "Dr. M. Prabu",
    //     "Dr. S. Ganesha",
    //     "Dr. M. Gobikrishnan",
    //     "Dr. T. Anita Padma Priya"
    // ];

    // const [activeTab, setActiveTab] = useState(0);
    // const handleTabChange = (tabIndex) => {
    //     setActiveTab(tabIndex);
    // };

    // useEffect(() => {
    //     axios.get(domain + '/api/committee/members')
    //         .then(response => {
    //             setData(response.data.data.sort(function (a, b) {
    //                 var x = a.order < b.order ? -1 : 1;
    //                 return x;
    //             }));
    //         })

    // }, []);

    useEffect(() => {
        axios.get(domain + '/api/committee/members')
            .then(response => {
                const responseData = response.data.data;
                if (typeof responseData === 'object' && responseData !== null) {
                    // Ensure that the response data is an object and not null
                    const sortedData = {
                        "committees": responseData.committees.sort((a, b) => a.order - b.order),
                        "others": responseData.others.sort((a, b) => a.order - b.order),
                        "patrons": responseData.patrons.sort((a, b) => a.order - b.order),
                        "advisors": responseData.advisors.sort((a, b) => a.order - b.order)
                    };
                    setData(sortedData);
                } else {
                    console.error('Invalid response data:', responseData);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // useEffect(() => {
    //     axios.get('http://api.nephrologyassociationofcoimbatore.com/api/event?pastEvents=1')
    //         .then(response => {
    //             setprevEventDate(response.data.data);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });

    // }, []);
    // useEffect(() => {
    //     axios.get('http://api.nephrologyassociationofcoimbatore.com/api/event?pastEvents=0')
    //         .then(response => {
    //             setupEventDate(response.data.data);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });

    // }, []);
    return (
        <div className='mainPage'>
            <section className="">
                <div className='bannerImage d-flex align-items-center ' style={{
                    backgroundImage: `url(${Banner1})`
                }}>
                    <div className='d-flex flex-wrap mx-auto flex-column align-items-center justify-content-center'>
                        <img src={BannerLogo} className='bannerLogo mb-3 mb-md-4 mb-lg-5 ' alt='bannerLogo' />
                        <h1 className='bannerBigText'>Indian Society of </h1>
                        <h2 className='bannerBigText'>Nephrology</h2>
                        <Link to={"/conference"} className="learnMore text-center">Explore</Link>
                    </div>


                </div>
                {/* <Swiper
                        loop={true}
                        keyboard={true}
                        className="heroSwiper"
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: true,
                        }}
                        spaceBetween={70}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Autoplay, Pagination, Keyboard]}

                    >
                        {
                            SliderArray.map((content) => {
                                return (

                                    <SwiperSlide>
                                        <div className="row">
                                            <div className='col-md-12 col-lg-6'>
                                                <h1 className='heroLeftTitle'>{content.title}</h1>
                                                <Link to={"/conference"} className="learnMore">{content.Anchor}</Link>
                                            </div>
                                            <div className='col-md-12 col-lg-6'>
                                                <div className="rightImage">
                                                    <img src={content.sliderImage} alt="aboutofkidney" />
                                                </div>
                                            </div>

                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper> */}
            </section>
            <section className='sectionTwo' id="members">
                <div className='sectionTwoPadding container'>
                    <div className='row'>
                        <div className='col-md-12 col-lg-6 col-sm-12 col-12'>
                            <div className='stickyDiv'>
                                <h1 className='memberTitle'>Our Executive Committee Members</h1>
                                <p className='memberSubTitle'>Meet our Committee Members</p>
                            </div>

                        </div>
                        <div className='col-md-12 col-lg-6 col-sm-12 col-12'>
                            <div className="members">
                                {data.committees && (data.committees.map((item, index) => {
                                    return (
                                        <div className="memberDetails" key={"item" + index}>
                                            <img src={item.photo} alt="memberPicture" />
                                            <h1 className='memberName'>{item.name} <span className='memberStudies'> {item.studies}</span></h1>
                                            <span className='memberPosition'>{item.department}</span>
                                            {/* <span className='memberPosition'>{item.workingHospital}</span> */}
                                            {/* <span className='memberPosition'>Year of Experience - {item.yearsOfExperience}</span> */}
                                        </div>
                                    )
                                }))}
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Doctors heading={"PATRONS"} details={data.patrons} widthOne={"bigWidthOne"} widthTwo={"smallWidthOne"} />
            <Doctors heading={"ADVISORS"} details={data.advisors} padding={"pt-0"} widthOne={"bigWidthTwo"} widthTwo={"smallWidthTwo"} />

            <section className={`sectionThree container pt-0`}>
                <p className='headingSmall'>Office Bearers</p>
                <div className="d-flex flex-wrap justify-content-center px-3 px-md-0 px-lg-0">
                    {/* {otherDoctors &&
                        otherDoctors.map((_doctor) => (
                            <div className="col-lg-3 col-12 col-md-6 col-sm-12">
                                <div className="d-flex align-items-center headingOthers">
                                    <p className="mb-0">{_doctor}</p>
                                </div>
                            </div>
                        ))} */}

                    {data.others && data.others.map((data) => {
                        const url = (data.photo == null || data.photo.length == 0) ? docplaceholder : data.photo
                        return (
                            <div className="officeDiv">
                                {<img src={url} alt='office' className='officeImage' />}
                                {data.name && <p className=' officeName'>{data.name}</p>}
                            </div>
                        )

                    })}
                </div>
            </section>
            {/* <section className='sectionThree' id="conference">
                <h1 className='tabHeading'>Conference by Coimbatore</h1>
                <p className='tabHeading'>Nephrology Association</p>
                <div className='btnRelative'>
                    <div className='btnGroup'>
                        <button type="button" className={`tabTitle ${activeTab === 0 && "active"}`} onClick={() => handleTabChange(0)}>Past Events</button>
                        <button type="button" className={`tabTitle  ${activeTab === 1 && "active"}`} onClick={() => handleTabChange(1)}>Upcoming Events</button>
                    </div>
                </div>
                {
                    activeTab === 0 ? <TabSlider key={activeTab} data={prevevent} /> : <TabSlider key={activeTab} data={upevent} />
                }

            </section> */}
            <section className='sectionFour' id="gallerys">
                <Gallery text="index" images={getImage} />
            </section >
            <section className='sectionFive' id="contact">
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-12 col-lg-6'>
                            <div className='contactLeft'>
                                <div className='follow'>
                                    <h1 className='contactTitle'>Let's Get In Touch with Us</h1>
                                    <div className='socialMedia'>
                                        <Link to="/">
                                            <img src={instagram} alt="instagramLogo" />
                                        </Link>
                                        <Link to="/">
                                            <img src={facebook} alt="facebookLogo" />
                                        </Link>
                                    </div>

                                </div>
                                <div className='address'>
                                    <p className='addressHeading'>Administrative Office</p>
                                    <address>
                                        Nephrology Association of Coimbatore,<br />
                                        D701, TULIP Apartments, Phase2, <br />
                                        Udayamplayam Road, Nava India, <br />
                                        Sowripalayam, Coimbatore.<br />
                                    </address>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-12 col-lg-6 contactRight'>
                            <form action="https://public.herotofu.com/v1/2d5e8090-27a1-11ee-9907-0b23fd627d84" method="post" accept-charset="UTF-8" autocomplete="off">
                                <div className="contactBox">
                                    <label>Enter Your Name</label>
                                    <input type="text" name="Name" className='contactField' required />
                                    <label>Enter Your Email</label>
                                    <input type="email" name="Email" className='contactField' required />
                                    <label>Enter Your Phone Number</label>
                                    <input type="number" name="Phone" className='contactField' required />
                                    <button type='submit' className='contactBtn'>Submit</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div >
            </section >
        </div >
    )
}
export default IndexPage